<template>
  <v-snackbar
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    :multi-line="snackbar.mode === 'multi-line'"
    :vertical="snackbar.mode === 'vertical'"
    v-model="snackbar.status"
  >
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text icon v-bind="attrs" @click="snackbar.status = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description NotificationBar Component Logic
   * @date 27.07.2020
   */
  export default {
    name: "NotificationBar",
    data() {
      return {
        snackbar: {
          status: false,
          color: "primary",
          mode: "",
          x: null,
          y: null,
          timeout: 2500,
          text: ""
        }
      };
    },
    methods: {
      showSnackbar({ text, color, no_translate } = {}) {
        this.snackbar.text = no_translate ? text : text ? this.$t(`message.${text}`) : null;
        this.snackbar.color = color;
        this.snackbar.status = true;
      }
    },
    created() {
      this.$eventBus.$on("snack-bar-notification", notification_credentials => {
        this.showSnackbar(notification_credentials);
      });
    }
  };
</script>

<style scoped></style>
